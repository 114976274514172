define(['lodash', 'santa-components', 'coreUtils', 'svgShape/skins/skins.json'], function (_, santaComponents, coreUtils, skinsJson) {
    'use strict';

    const skinRenderer = santaComponents.utils.skinsRenderer,
        defaultSkinName = 'skins.viewer.svgshape.SvgShapeDefaultSkin',
        defaultSkinHtml = '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 376.654 376.654"><g><polygon points="298.185,264.061 149.092,352.082 0,264.061 0,88.021 149.092,0 298.185,88.021 "/></g></svg>';

    const getSvgString = function (siteShape, skinName) {
        if (skinName === defaultSkinName) {
            return defaultSkinHtml;
        }
        if (siteShape) {
            return siteShape;
        }
        // no data
        return null;
    };

    const getCompCss = (styleId, props) => {
        const {getStyleData,
            themeData,
            siteZoomRatio,
            invertedZoomRatio,
            orientationZoomFix,
            mobileZoom,
            scriptsLocationMap} = props;

        const styleData = getStyleData(styleId);
        const mobileData = {siteZoomRatio, invertedZoomRatio, orientationZoomFix, mobileZoom};
        const serviceTopology = {scriptsLocationMap};
        const getSkin = skinId => skinsJson[skinId];
        const styleProps = _.get(styleData, 'style.properties', {});

        const defaultSkin = skinsJson[defaultSkinName];
        const shapeSkin = {
            css: defaultSkin.css,
            params: defaultSkin.params,
            paramsDefaults: defaultSkin.paramsDefaults
        };

        return {[styleId]: skinRenderer.createSkinCss(shapeSkin, styleProps, themeData, styleId, mobileData, serviceTopology, getSkin)};
    };

    const cssTypes = {
        getStyleData: santaComponents.santaTypesDefinitions.Component.getStyleData,
        themeData: santaComponents.santaTypesDefinitions.Theme.THEME_DATA,
        siteZoomRatio: santaComponents.santaTypesDefinitions.Mobile.siteZoomRatio,
        invertedZoomRatio: santaComponents.santaTypesDefinitions.Mobile.invertedZoomRatio,
        orientationZoomFix: santaComponents.santaTypesDefinitions.Mobile.orientationZoomFix,
        mobileZoom: santaComponents.santaTypesDefinitions.Mobile.mobileZoom,
        scriptsLocationMap: santaComponents.santaTypesDefinitions.ServiceTopology.scriptsLocationMap
    };

    getCompCss.cssTypes = cssTypes;

    function wrapSvgWithLink(svgString, linkObject) {
        let anchorAttributes = '';
        _.forOwn(linkObject, function (value, key) {
            anchorAttributes += ` ${key}="${value}"`;
        });

        return `<a${anchorAttributes}>${svgString}</a>`;
    }

    /**
     * @class components.SvgShape
     * @extends {ReactCompositeComponent}
     * @property {comp.properties} props
     */
    return {
        displayName: 'SvgShape',
        mixins: [santaComponents.mixins.baseCompMixin],

        statics: {
            getCompCss
        },

        propTypes: {
            id: santaComponents.santaTypesDefinitions.Component.id,
            structure: santaComponents.santaTypesDefinitions.Component.structure,
            skin: santaComponents.santaTypesDefinitions.Component.skin,
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo,
            svgString: santaComponents.santaTypesDefinitions.VectorImage.legacySvgString.isRequired,
            svgInfo: santaComponents.santaTypesDefinitions.VectorImage.legacySvgInfo.isRequired,
            styleId: santaComponents.santaTypesDefinitions.Component.styleId,
            linkRenderInfo: santaComponents.santaTypesDefinitions.Link.renderInfo,
            setCustomClickOccurred: santaComponents.santaTypesDefinitions.setCustomClickOccurred,
            strokeWidth: santaComponents.santaTypesDefinitions.VectorImage.strokeWidth,
            isQAMode: santaComponents.santaTypesDefinitions.isQAMode,
            // For render plugins
            renderFixedPosition: santaComponents.santaTypesDefinitions.Component.renderFixedPosition
        },

        // NOTE: getSkinProperties is inherited, do not remove it
        getSkinProperties() { // eslint-disable-line complexity
            const skinName = this.props.skin;
            const refData = {'': {}};
            let svgString = getSvgString(this.props.svgString, skinName);
            if (svgString) {
                const compData = this.props.compData;

                if (compData && compData.link) {
                    const linkObject = coreUtils.linkRenderer.renderLink(compData.link, this.props.linkRenderInfo, this.props.rootNavigationInfo);
                    linkObject.tabIndex = '-1';
                    svgString = wrapSvgWithLink(svgString, linkObject);
                }

                refData[''] = {
                    'data-viewbox': this.props.svgInfo.viewBox,
                    'data-strokewidth': this.props.strokeWidth,
                    'data-svg-id': this.props.skin,
                    'data-display-mode': this.props.compProp.maintainAspectRatio ? 'legacyFit' : 'stretch',
                    key: !this.props.compProp.maintainAspectRatio && this.props.strokeWidth ? 'stretchWithStroke' : 'noPoly',
                    dangerouslySetInnerHTML: {__html: svgString || ''},
                    tabIndex: '0',
                    role: 'image',
                    onKeyDown(evt) {
                        if (evt.key === 'Enter' || evt.key === ' ') {
                            const link = evt.target.querySelector('a');
                            _.invoke(link, 'click');
                        }
                    }
                };
            }

            this.updateRootRefDataStyles(refData['']);

            return refData;
        },

        render() {
            const refData = this.getSkinProperties();

            return skinRenderer.renderSkinHTML(null, refData, this.props.styleId, this.props.id, this.props.structure, this.props, this.state, this.props.isQAMode);
        }
    };
});
